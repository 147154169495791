import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Formify<br />
          technology ➝ React, Next.js, R3F, three.js<br />
          location ➝ Amsterdam (Netherlands)</p>
        <p>Formify is an all-in-one SaaS solution that delivers visual CPQ (configure-price-quote), live rendering, and e-commerce capabilities in a web-based, platform-agnostic tool.</p>
        <p>As a senior engineer, I developed a kitchen configurator using Three.js, leveraging my previous experience building configurators. I implemented core functionality, including kitchen, door, and upper cabinet modules, while also performing various performance optimizations.</p><br />
      </div>
      {/* <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/eo-digital-showroom-stage.jpg" alt="" /></div>
      </div> */}
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/formify/kitchen-configurator_0.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/formify/kitchen-configurator_2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/formify/kitchen-configurator_5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/formify/kitchen-configurator_6.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const FORMIFY = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• Formify •</h1>
      <Gallery />
    </div>
  )
}